
export class MigrationLogs {
  public errorLogs: MigrationErrorLogs[] = [];
  public summaryLogs: MigrationSummaryLogs[] = [];
}

export class MigrationSummaryLogs {
  public FilesUploaded: string;
  public FilesFailed: string;
  public TimeToComplete: number;
}

export class MigrationErrorLogs {
  public errorMessage: string;
  public filename: string;
  public itemname: string;
  public itemnum: string;
}
