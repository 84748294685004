
  <!-- <div class="row" style="width: 100%"> -->
    <!--
    <div class="col-md-3">
      <div class="card">
        <app-form-page-rule-setup-list [currentForm]="currentForm" style="padding: 15px"
                                       (ruleSetupChanged)="onRuleSetupChanged($event)"></app-form-page-rule-setup-list>
      </div>

    </div>
  -->

    <div class="col-md-12">
      <div class="card">
        <div>
        </div>
        <app-form-page-rule-setup [currentForm]="currentForm" [lobList]="lobList" style="padding: 0px" (saveForm)="onSaveRuleSetup()" (changeRuleSetup)="onRuleSetupChanged($event)"></app-form-page-rule-setup>
      </div>
    </div>
 



