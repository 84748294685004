<div class="component-container">
  <div class="col-12">
    <mat-tab-group class="vex-tabs">
      <mat-tab label="Production">
        <div style="font-size:30px;font-family:EncodeSans-Regular !important;padding-top: 5px;">Ghostdraft Production Summary 3/13 - 4/13</div>
        <div class="iframe-container">
          <iframe title="Ghostdraft Document Summary 3/13 - 4/13"
            src="https://app.powerbi.com/reportEmbed?reportId=6278def5-465d-4ff9-91d1-06644425c984&autoAuth=true&ctid=28e5a145-70c6-44e3-ba17-7b09d54fe531"
            frameborder="0" allowFullScreen="true">
          </iframe>
        </div>
      </mat-tab>
      <mat-tab label="Errors">
        <div style="font-size:30px;font-family:EncodeSans-Regular !important;padding-top: 5px;">Ghostdraft Errors 3/13 - 4/13</div>
        <div class="iframe-container">
          <iframe title="Ghostdraft Errors"
            src="https://app.powerbi.com/reportEmbed?reportId=886ec6e9-9023-417d-bb76-94e1d02f1ac6&autoAuth=true&ctid=28e5a145-70c6-44e3-ba17-7b09d54fe531"
            frameborder="0" allowFullScreen="true">
          </iframe>
        </div>
      </mat-tab>
      <mat-tab label="Audit">
        <div style="font-size:30px;font-family:EncodeSans-Regular !important;padding-top: 5px;">Premium Audit Report</div>
      
        <div id="data-grid-container">
          <ag-grid-angular class="ag-theme-balham fast_panel_table"
                           style="height:500px;"
                           [rowData]="rowData"
                           [columnDefs]="columnDefs"
                           (cellValueChanged)="onCellValueChanged($event)"
                           >
          </ag-grid-angular>
        </div>
      </mat-tab>
    </mat-tab-group>
  </div>
</div>




<script src="../MGDReports.module.js"></script>
