
  <div class="component-content-view">
    <!-- <div class="row" style="width: 100%">-->
      <div class="col-md-12">
        <div style="margin-top: 10px;">
          <!-- <label style="font-weight:bold" class="label_heading float_none">Deployment History:</label> -->
          <ag-grid-angular class="ag-theme-balham fast_panel_table"
          style="
          height: calc(100vh - 420px) !important;
          overflow-x: hidden !important;
          border-radius: 7px;
          box-shadow: 0 0 7px #fff;
        
        "
                           [gridOptions]="formHistoryGridOptions">
          </ag-grid-angular>
        </div>
      <!-- </div> -->
   </div> 
     <!-- <div class="row" style="width: 100%"> -->
      <div class="col-md-12"> 
        <div>
          <label style="font-weight:bold;margin-top: 1px;" class="label_heading float_none">Forms (Selected Package) :</label>
          <ag-grid-angular class="ag-theme-balham fast_panel_table"
          style="
          height: calc(100vh - 430px) !important;
          overflow-x: hidden !important;
          border-radius: 7px;
          box-shadow: 0 0 7px #fff;
        
        "
                           [gridOptions]="formsGridOptions">
          </ag-grid-angular>
        </div>
      </div>
     <!-- </div> -->
  </div> 

