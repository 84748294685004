//Angular Imports 
import { Component, ElementRef, HostBinding, Renderer2, ViewChild } from '@angular/core';

//Third Party Imports 
import { CellValueChangedEvent, ColDef, GridOptions } from 'ag-grid-community';
import * as _ from 'lodash';
import { ToastrService } from 'ngx-toastr';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DatePipe } from '@angular/common';

//Internal Imports 


import 'rxjs';
import { AppInsightsService, EnvironmentService, DateTimeUtcPipe, SharedService, ApplicationInsightsBaseComponent, CPMElement, DateFilterComponent } from '../../../framework';
import { EXECUTION_ENGINE_LIST } from '../../../report/configs';
import { MGDReportsService, PremiumAudit } from '../services/MGDReports.service';
import { isNull } from 'lodash';
import { AgGridDatePickerComponent } from '../../formsList/components/transactions/edit-date.component';

/**
 * Forms Component
*/
@Component({
  selector: 'app-mgd-reports',
  templateUrl: './MGDReports.component.html',
  styleUrls: ['./MGDReports.component.scss'],
  providers: [MGDReportsService]
})

export class MGDReportsComponent extends ApplicationInsightsBaseComponent {

  @HostBinding('style.width') width: string;
  @ViewChild('audit', { static: true }) audit: ElementRef;

  public premiumGridOptions: GridOptions;

  initialRowDataLoad$;

  reportNames: string[] = ['Production', 'Errors', 'Audit'];
  selectedReport: string = "Production";
  rowData: PremiumAudit[];
  columnDefs: ColDef[];


  /** 
   * Constructor
   * @ignore
  */
  constructor(private _appInsightsService: AppInsightsService,
    private toastr: ToastrService,
    private _modal: NgbModal,
    private renderer: Renderer2,
    private reportsService: MGDReportsService,
    private sharedService: SharedService,
    private dateTimePipe: DateTimeUtcPipe,
    //private envService: EnvironmentService,
    private environmentService: EnvironmentService) {
    super(_appInsightsService);
    this.width = "100%";
  }

  //Angular Lifecycles
  /**
   * NgOnInit
   * @ignore
  */
  ngOnInit(): void {
    this.sharedService.getExtensionJWT().subscribe(token => {
      this.reportsService.getAllPremiumAudit(this.environmentService.environmentDetails.Environment.toLowerCase(), token).subscribe(res => {
        //sort by date
        res.sort((a, b) => {
          // if (a.IssuedOn && b.IssuedOn) {
          //   return new Date(a.IssuedOn) - new Date(b.IssuedOn);
          //} else
          if (a.IssuedOn > b.IssuedOn) {
            return -1;
          } else if (b.IssuedOn > a.IssuedOn) {
            return 1;
          } else {
            return 0;
          }
        });
        this.rowData = res;
      })
    })
    this.configureGrid();

  }

  /**
   * NgOnDestroy
   * @ignore
  */
  ngOnDestroy(): void {

  }

  changeReport(event) {
    console.log(event);
    this.selectedReport = event.options[0]._value;
  }

  private configureGrid(): void {
    this.initialRowDataLoad$ = [];
    this.premiumGridOptions = <GridOptions>{
      rowSelection: "single",
      domLayout: 'autoHeight',
      columnDefs: this.createColDefs(),
      enableFilter: true,
      enableSorting: true,
      pagination: true,
      enableColResize: true,
      paginationPageSize: 20,
      enableBrowserTooltips: true,
      frameworkComponents: {
        dateFilterComponent: DateFilterComponent
      },
      onGridReady: () => {
        this.premiumGridOptions.api.setRowData([]);
        this.premiumGridOptions.api.sizeColumnsToFit();
        // this.gridOptions.api.softRefreshView();
      },
      onRowClicked: (event) => {
      },
      onFilterChanged: (event) => {
        //Api call to get the filter data
        //console.log(event);

      },
      onSortChanged: (event) => {

      },
      onFilterModified: function (event) {
        //console.log(event);
      }
    };

  }

  onCellValueChanged(event: CellValueChangedEvent) {
    console.log('Cell value changed', event.newValue);
    if (event.colDef.field == "ReviewedOn" && !Date.parse(event.newValue)) {
      event.data.ReviewedOn = null;
    }
    // call API to update this row
    const updatedData = [{
      ReviewedOn: event.data.ReviewedOn,
      ReviewedBy: event.data.ReviewedBy,
      TransactionId: event.data.TransactionId
    }];

    this.sharedService.getExtensionJWT().subscribe(token => {
      this.reportsService.updatePremiumAudit(this.environmentService.environmentDetails.Environment.toLowerCase(), token, updatedData).subscribe(res => {
        console.log(res);
      })
    });
  }

  createColDefs(): any[] {
    return this.columnDefs = [
      {
        field: "ReviewedBy",
        headerName: "Reviewed By",
        width: 155,
        tooltipField: "ReviewedBy",
        resizable: true,
        editable: true
      },
      {
        field: "ReviewedOn",
        headerName: "Reviewed On",
        width: 125,
        tooltipField: "ReviewedOn",
        resizable: true,
        editable: true,
        cellEditorFramework: AgGridDatePickerComponent,
        filter: "dateFilterComponent",
        cellRenderer: (params) => {
          if (!params.value) {
            return "";
          }

          const datePipe = new DatePipe('en-US');
          const dateValue = new Date(params.value); // Parse the date string
          return datePipe.transform(dateValue, 'MM/dd/yyyy'); // Transform to MM/DD/YYYY format
        },
      },
      {
        field: "DealNumber",
        headerName: "Deal Number",
        width: 120,
        resizable: true,
        tooltipField: "DealNumber"
      },
      {
        field: "PolicyNumber",
        headerName: "Policy Number",
        width: 156,
        resizable: true,
        tooltipField: "PolicyNumber"
      },
      {
        field: "IssuedOn",
        headerName: "Issued On",
        sortable: true,
        resizable: true,
        tooltipField: "IssuedOn",
        filter: "dateFilterComponent",
        sort: "desc",
        cellRenderer: (params) => {
          if (!params.value) {
            return "";
          }

          const datePipe = new DatePipe('en-US');
          const dateValue = new Date(params.value); // Parse the date string
          return datePipe.transform(dateValue, 'MM/dd/yyyy'); // Transform to MM/DD/YYYY format
        },
      },
      {
        field: "CreatedBy",
        headerName: "Created By",
        width: 150,
        resizable: true,
        tooltipField: "CreatedBy"
      },
      {
        field: "PremiumName",
        headerName: "Premium Name",
        resizable: true,
        tooltipField: "PremiumName"
      },
      {
        field: "Change_Premium_From_e2",
        headerName: "E2 Premium",
        width: 135,
        resizable: true,
        tooltipField: "Change_Premium_From_e2"
      },
      {
        field: "FormPremiumAmount",
        headerName: "Form Premium",
        width: 134,
        resizable: true,
        tooltipField: "FormPremiumAmount"
      },
      {
        field: "DocumentNumber",
        headerName: "Form Number",
        width: 160,
        resizable: true,
        tooltipField: "DocumentNumber"
      },
      {
        field: "ActionType",
        headerName: "Action Type",
        width: 125,
        resizable: true,
        tooltipField: "ActionType"
      },
    ];

  }
}
