//Angular Imports 
import { Component, HostBinding, Input } from '@angular/core';

//Third Party Imports 
import { GridOptions } from 'ag-grid-community';
import * as _ from 'lodash';
import { ToastrService } from 'ngx-toastr';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

//Internal Imports 
import {
  ApplicationInsightsBaseComponent, AppInsightsService, DateFilterComponent, BooleanFilterComponent,
  DateTimeUtcPipe, SharedService, EnvironmentService
} from '../../../framework';
import { FormHistory, AdminForm } from '../../models';
import {NoteService} from '../../../user-notes/services';


import 'rxjs';
import { saveAs } from 'file-saver';

//import { RuleEngineResult, SingleFormResult, RuleEngineLog, TestExecution, TestGroup, TestRun } from '../../models';



/**
 * Forms Component
*/
@Component({
  selector: 'app-form-page-notes-tab',
  templateUrl: './form-page-notes-tab.component.html',
  styleUrls: ['./form-page-notes-tab.component.scss']
})

export class FormPageNotesTabComponent extends ApplicationInsightsBaseComponent {

  @HostBinding('style.width') width: string;

  @Input() formHistory: FormHistory;

  @Input() public currentForm: AdminForm;

  formHistoryGridOptions: GridOptions;

  /** 
   * Constructor
   * @ignore
  */
  constructor(private _appInsightsService: AppInsightsService, private dateTimePipe: DateTimeUtcPipe, private noteService: NoteService) {
    super(_appInsightsService);
    this.width = "100%";
  }


  ngOnInit(): void {
    // this.currentRuleSetup = this.currentForm.RuleSetups.find(x => x.Name === "Default");
    this.configureGrid();

    //console.log("FormHistory: " + this.formHistory);
  }

  ngOnDestroy(): void {

  }

  ngOnChanges(): void {
    //console.log("FormHistory: " + this.formHistory);
    /*(if (this.formHistoryGridOptions) {
      if (this.formHistory) {
        this.formHistoryGridOptions.api.setRowData(this.formHistory.NotesHistory);
      } else {
        this.formHistoryGridOptions.api.setRowData([]);
      }
    } */
    this.noteService.getNotesByFormId(this.currentForm.id).subscribe(res => {
          this.formHistory.NotesHistory = res;
          this.formHistoryGridOptions.api.setRowData(this.formHistory.NotesHistory);
        });
  }

  private configureGrid(): void {
    this.formHistoryGridOptions = <GridOptions>{
      rowSelection: "single",
      domLayout: 'normal',
      columnDefs: this.createColumDef(),
      enableFilter: true,
      enableSorting: true,
      pagination: true,
      enableColResize: true,
      paginationPageSize: 20,
      frameworkComponents: {
        dateFilterComponent: DateFilterComponent,
        booleanFilterComponent: BooleanFilterComponent
      },
      onGridReady: () => {
        this.formHistoryGridOptions.api.setRowData(this.formHistory.NotesHistory);
        this.formHistoryGridOptions.api.sizeColumnsToFit();
        this.formHistoryGridOptions.columnApi.getColumn("CreationDate").setSort("desc")
        // this.gridOptions.api.softRefreshView();
      },
      onRowClicked: (event) => {
        //this.onRowClicked(event);
      },
      onFilterChanged: (event) => {
        //Api call to get the filter data
        //console.log(event);

      },
      onSortChanged: (event) => {

      },
      onFilterModified: function (event) {
        //console.log(event);
      }
    };

  }

  /**
   * Configure Columns
   */
  private createColumDef(): any[] {
    let result: any[] = [
      { headerName: 'Created By', field: 'CreatedByName', filter: 'agTextColumnFilter', resizable: true, comparator: function (valueA, valueB, nodeA, nodeB, isInverted) { return valueA.toLowerCase().localeCompare(valueB.toLowerCase()); } },
      {
        headerName: 'Creation Date', field: 'CreationDate', resizable: true, filter: 'dateFilterComponent', sortable: true, sort:'desc', cellRenderer: params => {
          return this.dateTimePipe.transform(params.value);
        }
      },     
      { headerName: 'Note', field: 'NoteText', filter: 'agTextColumnFilter', resizable: true, comparator: function (valueA, valueB, nodeA, nodeB, isInverted) { return valueA.toLowerCase().localeCompare(valueB.toLowerCase()); } },
      { headerName: 'Section Saved From', field: 'Category', filter: 'agTextColumnFilter', resizable: true, comparator: function (valueA, valueB, nodeA, nodeB, isInverted) { return valueA.toLowerCase().localeCompare(valueB.toLowerCase()); }}
    ]

    return result;
  }
}
