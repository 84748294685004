//Angular Imports
import { Component, HostBinding } from "@angular/core";

//Third Party Imports

import * as _ from "lodash";
import { ToastrService } from "ngx-toastr";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";

//Internal Imports
import {
  ApplicationInsightsBaseComponent,
  AppInsightsService,
  DateFilterComponent,
  BooleanFilterComponent,
  DateTimePipe,
  SharedService,
  EnvironmentService,
  UserProfileService,
  AuthorizationService,
} from "../../../framework";
import { FormPageAttributesTabComponent } from "../../components/form-page-attributes-tab/form-page-attributes-tab.component";
import { FormPageAddRuleSetupComponent } from "../../components/form-page-add-rule-setup/form-page-add-rule-setup.component";
import { FormPageService } from "../../services";
import { AdminForm, FormHistory, FormNumber } from "../../models";
import { AddNoteComponent } from "../../../user-notes/components";
import { NoteService } from "../../../user-notes/services";
//import { ExecutionService, TestingService } from '../../services';

import "rxjs";
import { saveAs } from "file-saver";
import { FormPageProductDefinition } from "../form-page-product-def/form-page-product-def";
import { FormControl } from "@angular/forms";
import {
  debounceTime,
  map,
  startWith,
  distinctUntilChanged,
} from "rxjs/operators";
import { switchMap } from "rxjs/operators";
import { Observable } from "rxjs";
import { of } from "rxjs";

/**
 * Forms Component
 */
@Component({
  selector: "app-form-page-container",
  templateUrl: "./form-page-container.component.html",
  styleUrls: ["./form-page-container.component.scss"],
})
export class FormPageContainerComponent extends ApplicationInsightsBaseComponent {
  @HostBinding("style.width") width: string;

  // List of all forms in the system used to populate the search dropdown.
  public formsList: FormNumber[] = [];
  public searchControl = new FormControl();

  // The form currently selected from the search dropdown.
  public selectedSearchForm: string;

  // The form currently populating the page
  public currentForm: AdminForm;
  public isExpanded = false;
  // The InternalFormNumber of the current Form.  This is being stored so we can compare this against the
  // InternalFormNumber on currentForm to determine if the form has changed.
  currentInternalFormNumber: string;

  // Determines which component (tab) is shown in the page
  public activeSection;

  // List of form numbers populating the recent dropdown
  public recentList: string[];

  // The form currently selected from the recent dropdown.
  public selectedRecent: string;

  // Used to populate the History tab
  public formHistory: FormHistory;

  // Indicates whether the currentForm populating the page is a new or copied for that has not yet
  // been saved to the database.
  isNewForm: boolean;

  public lobList: string[] = [];

  public selectedInput: string = "formnum";
  selectedTabIndex: number = 0;
  public isFormNumberEntered = false;
  private isEditing = false;

  public formOptions: any[] = [
    { value: "formnum", option: "Search By Form #" },
    { value: "formname", option: "Search By Form Name" },
  ];
  public filter = "default";

  recentSearches: {
    id: string;
    InternalFormNumber: string;
    FormName: string;
  }[] = [];
  filteredForms: Observable<any[]> = of([]);

  /**
   * Constructor
   * @ignore
   */
  constructor(
    private _appInsightsService: AppInsightsService,
    private toastr: ToastrService,
    private _modal: NgbModal,
    private sharedService: SharedService,
    private formPageService: FormPageService,
    private userProfileService: UserProfileService,
    private authService: AuthorizationService,
    private noteService: NoteService
  ) {
    super(_appInsightsService);
    this.width = "100%";
    this.recentList = [];
  }

  get FormsWritePermission(): boolean {
    return this.authService.FormsWrite();
  }

  //Angular Lifecycles
  /**
   * NgOnInit
   * @ignore
   */
  ngOnInit(): void {
    this.activeSection = "Attributes";

    //this.recentList = ["011 1051 05 98"];

    // Initialize starting form
    this.initializeForm();
    this.setupSearchControl();

    this.selectedSearchForm = "Search Forms";
    this.selectedRecent = "Recent Forms";

    this.sharedService.getMasterData().subscribe((el) => {
      let masterLobList: any[] = el.LineOfBusinessList;
      this.lobList = [];
      for (const item of masterLobList) {
        this.lobList.push(item.Name + " - " + item.Description);
      }
      this.lobList = this.lobList.sort((a, b) => {
        if (a == b) {
          return 0;
        } else if (a < b) {
          return -1;
        } else return 1;
      });
    });
  }

  initializeForm(): void {
    this.formPageService.getAllFormNumbers().subscribe(
      (res) => {
        this.formsList = res;
        console.log(this.formsList);
        // Find starting form
        let startingForm: FormNumber;
        if (this.recentList && this.recentList.length > 0) {
          startingForm = this.formsList.find(
            (x) => x.InternalFormNumber === this.recentList[0]
          );
        } else {
          startingForm = this.formsList[0];
        }

        this.loadForm(startingForm.id);
      },
      (error) => {
        this.toastr.error(
          "Error occurred while loading forms list.",
          "Form Page"
        );
      }
    );
  }

  get isSearchInputEmpty(): boolean {
    return !this.searchControl?.value?.trim();
  }

  onRecentSearchSelected(recent: {
    id: string;
    InternalFormNumber: string;
    FormName: string;
  }): void {
    console.log("Recent search selected:", recent);
    const selectedForm = this.formsList?.find(
      (form) => form?.id === recent?.id
    );
    if (selectedForm) {
      // Set the search control value to the combined value
      const displayValue = `${recent.InternalFormNumber} - ${recent.FormName}`;
      this.searchControl.setValue(displayValue, { emitEvent: false });

      // Load the form using its ID
      this.loadForm(recent.id);
    } else {
      console.error("No matching form found for:", recent);
    }
  }

  private setupSearchControl(): void {
    this.filteredForms = this.searchControl.valueChanges.pipe(
      startWith(""),
      debounceTime(300),
      distinctUntilChanged(),
      switchMap((value) =>
        this.isEditing ? this._filter(value as string) : of([])
      )
    );
    this.searchControl?.valueChanges?.subscribe((value) => {
      if (value != null) {
        this.isFormNumberEntered =
          value.includes(" - ") || value.trim().length > 0;
        this.isEditing = true;
        this.onSearch();
      }
    });
  }

  private _filter(value: string): Observable<FormNumber[]> {
    const filterValue = value.toLowerCase();
    const delimiter = " - ";
    const hasDelimiter = filterValue.includes(delimiter);
    let filtered: FormNumber[] = [];
    if (hasDelimiter) {
      const [formNumberPart, formNamePart] = filterValue
        .split(delimiter)
        .map((part) => part.trim());
      filtered = this.formsList.filter(
        (form) =>
          (form.InternalFormNumber.toLowerCase().includes(formNumberPart) ||
            formNumberPart === "") &&
          (form.FormName.toLowerCase().includes(formNamePart) ||
            formNamePart === "")
      );
    } else {
      filtered = this.formsList.filter(
        (form) =>
          form.FormName.toLowerCase().includes(filterValue) ||
          form.InternalFormNumber.toLowerCase().includes(filterValue)
      );
    }
    return of(filtered.slice(0, 50));
  }

  onSearch(): void {
    const value = this.searchControl.value;
    this.filteredForms = this._filter(value);
  }

  onOptionSelected(id: string): void {
    this.isEditing = false;
    const selectedForm = this.formsList.find((form) => form?.id === id);
    if (selectedForm) {
      const displayValue = `${selectedForm.InternalFormNumber} - ${selectedForm.FormName}`;
      this.searchControl.setValue(displayValue, { emitEvent: false });
      this.loadForm(id);
      this.addRecentSearch(selectedForm);
      this.clearSearch();
    } else {
      console.error("No matching form found for:", id);
    }
  }

  clearSearch(): void {
    this.searchControl.setValue("", { emitEvent: false });
  }

  private addRecentSearch(form: FormNumber): void {
    if (form) {
      const recentSearch = {
        id: form.id,
        InternalFormNumber: form.InternalFormNumber,
        FormName: form.FormName,
      };
      const existingSearchIndex = this.recentSearches.findIndex(
        (search) => search.id === form.id
      );
      if (existingSearchIndex !== -1) {
        this.recentSearches.splice(existingSearchIndex, 1);
      }
      this.recentSearches.unshift(recentSearch);
    }
  }

  loadFormsList(): void {
    this.formPageService.getAllFormNumbers().subscribe(
      (res) => {
        this.formsList = res;
      },
      (error) => {
        this.toastr.error(
          "Error occurred while loading forms list.",
          "Form Page"
        );
      }
    );
  }

  private getFormNameFromAttributes(attributeSets: any[]): string {
    // Assuming you want to use the FormName from the first attribute set
    if (attributeSets.length > 0 && attributeSets[0].FormName) {
      return attributeSets[0].FormName;
    }
    return "Unknown Form Name";
  }

  updateSearchPlaceholder(): void {
    if (this.currentForm) {
      const displayValue = `${this.currentForm.InternalFormNumber} - ${this.currentForm.AttributeSets[0].FormName}`;
      this.searchControl.setValue(displayValue, { emitEvent: false });
    }
  }
  loadForm(id: string): void {
    this.formPageService.getAdminForm(id).subscribe(
      (res) => {
        this.currentForm = res;
        console.log(this.currentForm);
        this.currentInternalFormNumber = this.currentForm.InternalFormNumber;

        this.addFormToRecentList(this.currentForm.InternalFormNumber);
        const formName = this.getFormNameFromAttributes(
          this.currentForm?.AttributeSets || []
        );
        const formNumber: FormNumber = {
          id: this.currentForm?.id || "",
          InternalFormNumber: this.currentForm?.InternalFormNumber || "",
          FormName: formName,
        };

        this.addRecentSearch(formNumber);
        this.updateSearchPlaceholder();

        // Get the form history for selected form
        this.formHistory = new FormHistory();
        this.formPageService.getFormHistory(id).subscribe((res) => {
          this.formHistory.DeploymentHistory = res;
        });
        //Get all notes created for this form
        this.noteService.getNotesByFormId(id).subscribe((res) => {
          this.formHistory.NotesHistory = res;
        });

        this.currentForm.RuleSetups[0].IsDefault = true;

        this.isNewForm = false;
      },
      (error) => {
        this.toastr.error("Error occurred while loading form.", "Form Page");
      }
    );
  }

  addFormToRecentList(formNumber: string) {
    // Add the form to the beginning of the recent list.  If it's already on the list then move
    // it to the beginning.
    let index = this.recentList.indexOf(formNumber);
    if (index !== -1) {
      this.recentList.splice(index, 1);
    }
    this.recentList.unshift(formNumber);

    // clone the array to get the dropdown to update
    this.recentList = _.cloneDeep(this.recentList);
  }

  addFormToFormsList(form: AdminForm): void {
    this.formPageService.addFormNumberToLocalList(
      form.InternalFormNumber,
      form.id
    );
    // Reload the forms list to pick up the newly added form, then clone it to
    // force the dropdown to update.
    this.loadFormsList();
    this.formsList = _.cloneDeep(this.formsList);
  }

  /**
   * NgOnDestroy
   * @ignore
   */
  ngOnDestroy(): void {}

  // Event fired from child components when Save button clcicked
  onSaveForm(event) {
    var lastModified = this.currentForm.LastModified;
    if (this.isNewForm) {
      // Save new or copied form

      // Check to see if form # already exists
      //let searchForm: FormNumber = this.formsList.find(x => x.InternalFormNumber === this.currentForm.InternalFormNumber);
      let searchForm;
      if (!searchForm) {
        // Add new form to search list
        this.currentForm.InternalFormNumber = this.currentForm.InternalFormNumber.trim();
        this.currentForm.ExternalFormNumber = this.currentForm.ExternalFormNumber.trim();
        this.addFormToFormsList(this.currentForm);

        //let newFormNumber: FormNumber = new FormNumber;
        //newFormNumber.id = this.currentForm.id;
        //newFormNumber.InternalFormNumber = this.currentForm.InternalFormNumber;
        //this.formsList.push(newFormNumber);
        //this.formsList = _.cloneDeep(this.formsList); // clone array to force the dropdown to refresh

        // Add new form to recent forms list
        // this.addFormToRecentList(this.currentForm.InternalFormNumber);
        // this.addFormToRecentList(this.currentForm.InternalFormNumber);
        const formName = this.getFormNameFromAttributes(
          this.currentForm?.AttributeSets || []
        );
        const formNumber: FormNumber = {
          id: this.currentForm?.id || "",
          InternalFormNumber: this.currentForm?.InternalFormNumber || "",
          FormName: formName,
        };

        this.addRecentSearch(formNumber);

        event.CreatedByName = this.userProfileService.UserProfile.UserName;

        this.formPageService.addAdminForm(event).subscribe(
          (res) => {
            this.currentForm = res;
            this.currentInternalFormNumber = this.currentForm.InternalFormNumber;
            this.toastr.success("Form successfully saved.", "Form Page");
          },
          (error) => {
            this.toastr.error("Error occurred while saving form.", "Form Page");
          }
        );

        this.isNewForm = false;
      } else {
        this.toastr.error(
          "Error saving form.  Form Number already exists.",
          "Form Page"
        );
      }
    } else {
      console.log(event);
      // Save existing form
      event.ModifiedByName = this.userProfileService.UserProfile.UserName;
      event.InternalFormNumber = event.InternalFormNumber.trim();
      event.ExternalFormNumber = event.ExternalFormNumber.trim();
      this.formPageService.updateAdminForm(event).subscribe(
        (res) => {
          this.currentForm = res;
          this.toastr.success("Form successfully saved.", "Form Page");
          const modalRef = this._modal.open(AddNoteComponent);
          modalRef.componentInstance.linkedForms = [this.currentForm.id];
          modalRef.componentInstance.category =
            this.activeSection + ": " + lastModified;
          modalRef.result.then((result) => {
            if (result) {
              this.formHistory.NotesHistory.push(result);
            }
          });
        },
        (error) => {
          this.toastr.error("Error occurred while saving form.", "Form Page");
        }
      );
    }
  }

  // Event fired from child components when Discard button clicked.
  onDiscardChanges(): void {
    if (this.isNewForm) {
      // Remove the new form object and set current form to the first item in the recent list
      let form = this.formsList.find(
        (x) => x.InternalFormNumber === this.recentList[0]
      );
      this.loadForm(form.id);
    }
  }

  onSelectRecentForm(event) {
    // load new form into page
    let form = this.formsList.find(
      (x) => x.InternalFormNumber === this.selectedRecent
    );
    this.loadForm(form.id);
  }

  onSelectSearchForm(event) {
    // load new form into page
    this.loadForm(this.selectedSearchForm);
  }

  addForm(): void {
    try {
      this.isNewForm = true;
      this.currentInternalFormNumber = "";
      this.currentForm = this.formPageService.createNewForm();
    } catch (e) {
      this.toastr.error("Error creating new form.", "Form Page");
    }
  }

  copyForm(): void {
    this.isNewForm = true;
    try {
      this.isNewForm = true;
      this.currentInternalFormNumber = "";
      this.currentForm = this.formPageService.copyForm(this.currentForm);
    } catch (e) {
      this.toastr.error("Error copying the form.", "Form Page");
    }
  }

  addRuleSetup(): void {
    const modalRef = this._modal.open(FormPageAddRuleSetupComponent, {
      backdrop: "static",
      size: "lg",
    });
    modalRef.componentInstance.currentForm = this.currentForm;

    modalRef.result.then((result: AdminForm) => {
      if (result) {
        this.onSaveForm(result);
      }
    });
  }

  //Instead of making a new component we can just leverage FormPageAddRuleSetup to display all LOB codes and names in a read only format
  viewLOBCodes(): void {
    const modalRef = this._modal.open(FormPageProductDefinition, {
      backdrop: "static",
      size: "lg",
    });
    modalRef.componentInstance.currentForm = this.currentForm;
    modalRef.componentInstance.Action = "View";
  }

  //loadForm(formNumber: string) {
  //  //console.log(formNumber);
  //  let selectedForm: FormNumber = this.formsList.find(x => x.InternalFormNumber === formNumber);
  //  //this.currentForm = selectedForm;
  //  this.loadFormNew(selectedForm.id);

  //  // add to recent forms
  //  this.recentList.push({ Name: formNumber });
  //  // clone the array to get the dropdown to update
  //  this.recentList = [...this.recentList];
  //  //console.log(this.recentList);

  //  // Get the form history for selected form
  //  this.formHistory = TestHistory;
  //}

  //When the tab is changed on the FormBuilder page this variable is changed which controls the content displayed through the HTML file
  loadAttributes() {
    this.activeSection = "Attributes";
  }

  loadRules() {
    this.activeSection = "Rules";
  }

  loadNotes() {
    this.activeSection = "Notes";
  }

  loadDeploymentHistory() {
    this.activeSection = "History";
  }

  loadRulesEngine() {
    this.activeSection = "RulesEngine";
  }

  onTabChanged(index: number) {
    this.selectedTabIndex = index;
    switch (index) {
      case 0:
        this.loadAttributes();
        break;
      case 1:
        this.loadRules();
        break;
      case 2:
        this.loadNotes();
        break;
      case 3:
        this.loadDeploymentHistory();
        break;
      case 4:
        this.loadRulesEngine();
        break;
      default:
        break;
    }
  }
}
