//Angular Imports
import { Component, HostBinding } from "@angular/core";
import { Router } from "@angular/router";
import { DatePipe } from "@angular/common";

//Third Party Imports
import { GridOptions } from "ag-grid-community";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import * as _ from "lodash";
import { ToastrService } from "ngx-toastr";
import { saveAs } from "file-saver";

//Internal Imports
import {
  ApplicationInsightsBaseComponent,
  AppInsightsService,
  SharedService,
  DateFilterComponent,
  BooleanFilterComponent,
  DateTimeUtcPipe,
  AuthorizationService,
} from "../../framework";
import { ProductsService } from "../services/products.service";
import { AddProductComponent } from "./add-product/add-product.component";
import {
  ProductConfigWrapper,
  ProductCoverage,
  ProductDefinition,
} from "../models/productDefinition";

/**
 * Forms Component
 */
@Component({
  selector: "products",
  templateUrl: "./products.component.html",
  styleUrls: ["./products.component.scss"],
})
export class ProductsComponent extends ApplicationInsightsBaseComponent {
  //Public Variables
  /**
   * Host binding for width
   */
  @HostBinding("style.width") width: string;
  public formsGridOptions: GridOptions;
  public policyFormList: any[];
  lineOfBusinessList: ProductDefinition[];
  public selectedLOB: string;
  coverageList: ProductCoverage[];
  selectedCoverage: string;
  editVisible: boolean = false;
  initialRowDataLoad$;

  /**
   * Constructor
   * @ignore
   */
  constructor(
    private _appInsightsService: AppInsightsService,
    private toastr: ToastrService,
    private productsService: ProductsService,
    private _modal: NgbModal,
    private sharedService: SharedService,
    private authService: AuthorizationService,
    private dateTimePipe: DateTimeUtcPipe
  ) {
    super(_appInsightsService);
    this.width = "100%";
  }

  get FormsWritePermission(): boolean {
    return this.authService.FormsWrite();
  }

  //Angular Lifecycles
  /**
   * NgOnInit
   * @ignore
   */
  ngOnInit(): void {
    this.productsService.getProducts().subscribe((res) => {
      this.lineOfBusinessList = res;
    });

    this.configureGrid();
  }

  /**
   * NgDestroy
   * @ignore
   */
  ngOnDestroy(): void {}

  //Public Methods
  onLOBChange(selectedvalue) {
    this.coverageList = this.lineOfBusinessList.filter(
      (x) => x.Name == selectedvalue.Name
    )[0].Coverages;
    this.formsGridOptions.api.setRowData(this.coverageList);
    this.editVisible = true;
  }

  onCoverageChange(selectedvalue) {}

  editProduct() {
    const modalRef = this._modal.open(AddProductComponent, {
      windowClass: "test-case-detail-modal",
    });
    modalRef.componentInstance.Action = "Edit";
    var covNames = this.coverageList.map((i) => {
      return i.ID + " - ";
    });
    modalRef.componentInstance.CurrentLOBs = covNames;
    modalRef.result.then((data) => {
      if (data) {
        var lobIndex = this.lineOfBusinessList.findIndex(
          (l) => l.Name == this.selectedLOB
        );
        this.lineOfBusinessList[lobIndex].Coverages = this.parseCoverages(
          data.LOBList
        );
        var requestBody = new ProductConfigWrapper();
        requestBody.Items = this.lineOfBusinessList;

        this.productsService.updateProducts(requestBody).subscribe((res) => {
          this.lineOfBusinessList = res;
          this.onLOBChange(this.lineOfBusinessList[lobIndex]);
        });
      }
    });
  }

  newProduct() {
    const modalRef = this._modal.open(AddProductComponent, {
      windowClass: "test-case-detail-modal",
    });
    modalRef.componentInstance.Action = "New";
    modalRef.result.then((data) => {
      if (data) {
        var newProductDefinition = new ProductDefinition();
        newProductDefinition.Name = data.Name;
        newProductDefinition.ID = data.Name.substring(0, 3);
        newProductDefinition.ID = newProductDefinition.ID.toUpperCase();
        newProductDefinition.Coverages = this.parseCoverages(data.LOBList);
        this.lineOfBusinessList = [
          ...this.lineOfBusinessList,
          newProductDefinition,
        ];

        var requestBody = new ProductConfigWrapper();
        requestBody.Items = this.lineOfBusinessList;

        this.productsService.updateProducts(requestBody).subscribe((res) => {
          console.log(res);
        });
      }
    });
  }

  parseCoverages(LobList: string[]): ProductCoverage[] {
    var covList: ProductCoverage[] = [];
    LobList.forEach((lob) => {
      var newCov = new ProductCoverage();
      newCov.ID = lob.substr(0, lob.indexOf("-")).trim();
      newCov.Name = lob.substr(lob.indexOf("-") + 1).trim();
      covList.push(newCov);
    });
    return covList;
  }

  //Private Methods
  /**
   * configure grid
   */
  private configureGrid(): void {
    this.initialRowDataLoad$ = [];
    this.formsGridOptions = <GridOptions>{
      rowSelection: "single",
      domLayout: 'normal',
      columnDefs: this.createColumDef(),
      enableFilter: true,
      enableSorting: true,
      pagination: true,
      enableColResize: true,
      paginationPageSize: 20,
      frameworkComponents: {
        dateFilterComponent: DateFilterComponent,
        booleanFilterComponent: BooleanFilterComponent,
      },
      onGridReady: () => {
        this.formsGridOptions.api.setRowData([]);
        this.formsGridOptions.api.sizeColumnsToFit();
        // this.gridOptions.api.softRefreshView();
      },
      onRowClicked: (event) => {
        this.onRowSelected(event);
        this.formsGridOptions.api.refreshCells();
      },
      onFilterChanged: (event) => {
        //Api call to get the filter data
        //console.log(event);
      },
      onSortChanged: (event) => {},
      onFilterModified: function (event) {
        //console.log(event);
      },
    };
  }

  private createColumDef(): any[] {
    let result: any[] = [
      {
        headerName: "LOB Code",
        field: "ID",
        sortable: true,
        filter: "agTextColumnFilter",
        resizable: true,
        comparator: function (valueA, valueB, nodeA, nodeB, isInverted) {
          return valueA.toLowerCase().localeCompare(valueB.toLowerCase());
        },
      },
      {
        headerName: "LOB Name",
        width: 300,
        field: "Name",
        sortable: true,
        resizable: true,
        comparator: function (valueA, valueB, nodeA, nodeB, isInverted) {
          return valueA.toLowerCase().localeCompare(valueB.toLowerCase());
        },
      },
      {
        headerName: "Actions",
        suppressMenu: true,
        suppressSorting: true,
        cellStyle: {
          display: "flex",
          "justify-content": "center",
          "align-items": "center",
        },
        width: 45,
        //hide: !this.FormsWritePermission,  // Hide column if user does not have write permission
        cellRenderer: (params) => {
          return `
          <img src="/assets/images/x_icon.png" data-action-type="Delete" class="cursor_pointer mrgrgt10" title="Delete" >
          `;
        },
      },
    ];

    return result;
  }

  onRowSelected(e) {
    if (e.event.target !== undefined) {
      let data = e.data;
      let actionType = e.event.target.getAttribute("data-action-type");
      switch (actionType) {
        case "Delete":
          if (confirm("Delete the selected test case?")) {
            return this.deleteTestCase(data);
          }
      }
    }
  }

  deleteTestCase(data) {
    var index = this.coverageList.findIndex((c) => c.ID == data.ID);
    this.coverageList.splice(index, 1);
    console.log(this.coverageList);

    var lobIndex = this.lineOfBusinessList.findIndex(
      (l) => l.Name == this.selectedLOB
    );
    this.lineOfBusinessList[lobIndex].Coverages = this.coverageList;

    var requestBody = new ProductConfigWrapper();
    requestBody.Items = this.lineOfBusinessList;

    this.productsService.updateProducts(requestBody).subscribe((res) => {
      this.lineOfBusinessList = res;
      this.onLOBChange(this.lineOfBusinessList[lobIndex]);
    });
  }
}
