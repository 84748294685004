
<div class="component-content-view">
  <!-- <div class="row" style="width: 100%"> -->
    <div class="col-md-12">
      <div style="margin-top: 10px;">
        <!-- <label style="font-weight:bold" class="label_heading float_none">Notes History:</label> -->
        <ag-grid-angular class="ag-theme-balham fast_panel_table"
        style="
        height: calc(100vh - 200px) !important;
        overflow-x: hidden !important;
        border-radius: 7px;
        box-shadow: 0 0 7px #fff;
      
      "
                         [gridOptions]="formHistoryGridOptions">
        </ag-grid-angular>
      </div>
    <!-- </div> -->
  </div>
</div>



