//Angular Imports
import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpContextToken } from '@angular/common/http';

//RxJS imports
import { Observable } from 'rxjs';

//Internal Imports
import { EnvironmentService } from '../services';
import { SkippableInterceptorHeader } from '../enums';


/**
  * Intercepts adds the necessary headers and server prefix to the HTTP Request 
*/
export const BYPASS_URL_APPEND = new HttpContextToken(() => false);
@Injectable()



export class HttpRequestInterceptor implements HttpInterceptor {

  /**
   * Constructor
   * @ignore
  */
  constructor(private environmentService: EnvironmentService) { }

  /**
   * Intercepts all the HTTP requests adds the necessary headers and server prefix to the HTTP Request
   * @param {HttpRequest} request
   * @param {HttpHandler} next
   * @returns {Observable<HttpEvent<any>>} Http Event Observable
  */
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    
    let headers = request.headers;

    //set the content type if not set 
    if ((!headers || !headers.get('content-type')) && request.url.indexOf('mgd') == -1 && request.url.indexOf('extension') == -1 && request.url.indexOf('mna') == -1
      && request.url.indexOf('BaseCPM') == -1 && request.url.indexOf('63161') == -1 && request.url.indexOf('doc-queue') == -1 && request.url.indexOf('53023') == -1
      && request.url.indexOf('svg') == -1 && request.url.indexOf('mdo-fileupload') == -1 && request.url.indexOf('uploadForm') == -1 && request.url.indexOf('pdfcompare') == -1) {
      request = request.clone({
        setHeaders: {
          "Content-Type": "application/json"
        },
        url: request.url
      })
    }
    
    //Added this logic because IE caches get request so data is not updated
    //Logic to add If modifed since to all get calls except ones with skippable header
    if (request.method == "GET" && !headers.get(SkippableInterceptorHeader.IfModifiedSkipHeader)) {
      request = request.clone({
        setHeaders: {
          "If-Modified-Since": "0"
        },
        url: request.url
      });
    }
    else if (request.method == "GET") {
      //delete the header if set 
      request = request.clone({
        headers: headers.delete(SkippableInterceptorHeader.RequestSkipHeader),
        url: request.url
      });
    }
    
    //Check if the request is not API call
    //Note from Christian: The statements below that check for request url 63161 are for GhostPortal testing purposes, this should not affect FAST since FAST is not calling GhostDraft API
    //Note from Christian: Those checks are needed in general because the FAST API url was being appended to our API calls. When a solution to handle this cleanly is found will implement ASAP
    //to ensure GhostPortal is as self contained as possible
    if (!headers.get(SkippableInterceptorHeader.RequestSkipHeader) && request.url.indexOf('mgd') == -1 && request.url.indexOf('extension') == -1 && request.url.indexOf('mna') == -1
      && request.url.indexOf('BaseCPM') == -1 && request.url.indexOf('63161') == -1 && request.url.indexOf('doc-queue') == -1 && request.url.indexOf('61000') == -1 && request.url.indexOf('53023') == -1 && request.url.indexOf('svg') == -1
      && request.url.indexOf('53001') == -1) {
      //construct URL, check if interceptor bypass is false, will eventually replace the url checks in the if statement above
      if (request.context.get(BYPASS_URL_APPEND) === false) {
        let url = `${this.environmentService.environmentDetails.API_ORIGIN}/${request.url}`;
        request = request.clone({
          url: url
        });
      }
      
    }
    else if (request.url.indexOf('mgd') == -1 && request.url.indexOf('extension') == -1 && request.url.indexOf('mna') == -1
      && request.url.indexOf('BaseCPM') == -1 && request.url.indexOf('63161') == -1 && request.url.indexOf('doc-queue') == -1 && request.url.indexOf('53023') == -1 && request.url.indexOf('svg') == -1
        )
      {
      //delete the header if set 
      request = request.clone({
        headers: headers.delete(SkippableInterceptorHeader.RequestSkipHeader),
        url: request.url
      });
    }
      
    //Pass to next interceptor
    return next.handle(request);
  }
}
