
<div class="component-container">

  <div class="component-heading-container">
    <h3 class="component-heading-text">View Forms By LOB</h3>
  </div>
  <div class="component-content-view">
    <div class="container-fluid">
      <div id="policy-form-container" class="row">
        <div class="col-md-12 p-0">
          <div
            class="heading-container fast_admin"
            id="policy-form-heading-container"
          >
            <div class="row" style="padding-left:20px">
              <label class="label_heading">Line Of Business:</label>
              <div class="selectdiv select_wrapinline" style="width: 400px">
                <label>
                  <ng-select id="lineOfBusiness"
                             class="custom-select"
                             [items]="lineOfBusinessList"
                             bindLabel="Name"
                             bindValue="ID"
                             (change)="onLOBChange($event)"
                             [(ngModel)]="selectedLOB">
                    <ng-template ng-option-tmp let-item="item">
                      <div title="{{ item.Name }}">{{ item.Name }}</div>
                    </ng-template>
                  </ng-select>
                </label>
              </div>

              <label class="label_heading" style="margin-left:29px">Coverage:</label>
              <div class="selectdiv select_wrapinline" style="width: 400px">
                <label>
                  <ng-select id="lineOfBusiness"
                             class="custom-select"
                             [items]="coverageList"
                             bindLabel="Name"
                             bindValue="ID"
                             (change)="onCoverageChange($event)"
                             [(ngModel)]="selectedCoverage">
                    <ng-template ng-option-tmp let-item="item">
                      <div title="{{ item.Name }}">
                        {{ item.ID }}: {{ item.Name }}
                      </div>
                    </ng-template>
                  </ng-select>
                </label>

              </div>
              <div class="btn_wrapper" style="margin-left: auto; margin-right: 0; margin-top: 8px;">
                <button class="white-text btn-teal mat-raised-button" (click)="runQuery()">
                  Export info to Spreadsheet
                </button>
              </div>
            </div>
            <div class="row" style="padding-left:20px;">
              <button class="white-text btn-teal mat-raised-button" (click)="showHidePanel()">{{updateButtonText}}</button>
              <button (click)="updateSelectedForms()" style="margin-left:20px;" *ngIf="this.showUpdatePanel">Update Selected Forms</button>
            </div>
            <div class="row" style="padding-left:20px;" *ngIf="this.showUpdatePanel">
              <table style="width: 100%">
                <tr>
                  <td>
                    <label class="label_heading">Form Type</label>
                    <div class="col-md-12  fast_admin">
                      <ng-select id="formType"
                                  class="custom-select"
                                  style="width: 100%"
                                  [items]="formTypeList"
                                  bindLabel="Description"
                                  bindValue="Description"
                                  [(ngModel)]="updateFormType"
                                  (change)="onChangeFormType($event)"
                                  required
                                  #formType="ngModel">
                      </ng-select>
                    </div>
                  </td>
                  <td>
                    <label class="label_heading">LOB Effective Date</label>
                    <div class="col-md-12 mrgbtm10 ">
                      <date-picker name="effectiveDate"
                                   id="effectiveDate"
                                   style="width: 100%"
                                   [(ngModel)]="updateEffectiveDate"></date-picker>
                    </div>
                  </td>
                  <td>
                    <label class="label_heading">LOB Expiration Date</label>
                    <div class="col-md-12 mrgbtm10 ">
                      <date-picker name="expirationDate"
                                   id="expirationDate"
                                   style="width: 100%"
                                   [(ngModel)]="updateExpirationDate"></date-picker>
                    </div>
                  </td>
                  <td>
                    <label class="label_heading">LOB Submission Expiration Date</label>
                    <div class="col-md-12 mrgbtm10 ">
                      <date-picker name="submissionExpirationDate"
                                   id="submissionExpirationDate"
                                   style="width: 100%"
                                   [(ngModel)]="updateSubmissionExpirationDate"></date-picker>
                    </div>
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </div>
        <div id="policy-form-content-container" class="col-md-12 mrgbtm10">
    
            <ag-grid-angular class="ag-theme-balham fast_panel_table"
        style="
        height: calc(100vh - 200px) !important;
        overflow-x: hidden !important;
        border-radius: 7px;
        box-shadow: 0 0 7px #fff;
      "
            [pinnedTopRowData]="pinnedTopRowData"
            [gridOptions]="formsGridOptions"
          >
          </ag-grid-angular>
        </div>
      </div>
    </div>
  </div>
</div>
