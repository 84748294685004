//Angular Imports 
import { Component, HostBinding } from '@angular/core';
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common'

//Third Party Imports 
import { GridOptions } from 'ag-grid-community';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as _ from 'lodash';
import { ToastrService } from 'ngx-toastr';
import { saveAs } from 'file-saver';

//Internal Imports 
import {
  ApplicationInsightsBaseComponent, AppInsightsService, SharedService, DateFilterComponent,
  BooleanFilterComponent, DateTimeUtcPipe, AuthorizationService
} from '../../../framework';

import { FormsListService } from '../../../ghostPortal/formsList/services';
import { AdminForm } from '../../../form-page';
import { RuleEngineForm } from '../../../form-page/models/ruleEngineForm.model';
import { PackageService } from '../../../package/services';
import { PolicyFormExport } from '../../models/policyFormExport.model';
import { PolicyFormsService } from '../../services';
import { isatty } from 'tty';


/**
 * Forms Component
*/
@Component({
  selector: 'policy-forms',
  templateUrl: './policy-forms.component.html',
  styleUrls: ['./policy-forms.component.scss']
})

export class PolicyFormsComponent extends ApplicationInsightsBaseComponent {

  //Public Variables
  /**
   * Host binding for width 
  */
  @HostBinding('style.width') width: string;
  public formsGridOptions: GridOptions;
  public policyFormList: any[];
  lineOfBusinessList: any[];
  public selectedLOB: string;
  coverageList: any[];
  selectedCoverage: string;
  public selectedFormsRules: RuleEngineForm[];
  public adminFormList: AdminForm[];

  public showUpdatePanel: boolean = false;
  public updateButtonText: string = "Show Update Panel";
  public formTypeList: string[];
  //Update Variables
  updateFormType: string = "";
  updateEffectiveDate: number;
  updateExpirationDate: number;
  updateSubmissionExpirationDate: number;

  public pinnedTopRowData;
  initialRowDataLoad$;

  /** 
   * Constructor
   * @ignore
  */
  constructor(private _appInsightsService: AppInsightsService,
    private toastr: ToastrService,
    private policyFormsService: PolicyFormsService,
    private _modal: NgbModal,
    private sharedService: SharedService,
    private authService: AuthorizationService,
    private dateTimePipe: DateTimeUtcPipe) {
    super(_appInsightsService);
    this.width = "100%";
  }

  get FormsWritePermission(): boolean {
    return this.authService.FormsWrite();
  }

  //Angular Lifecycles
  /**
   * NgOnInit
   * @ignore
  */
  ngOnInit(): void {
    this.sharedService.getMasterData().subscribe(
      (el) => {
        this.formTypeList = el.FormType;
      },
      (error) => {
        this.toastr.error(
          "Error occurred while fetching master data.",
          "Form Page"
        );
      }
    );
    this.policyFormsService.getProducts().subscribe(res => {
      this.lineOfBusinessList = res;
    })

    this.configureGrid();

  }

  /**
   * NgDestroy
   * @ignore
  */
  ngOnDestroy(): void {
  }

  showHidePanel() {
    this.showUpdatePanel = !this.showUpdatePanel;
    if (this.showUpdatePanel) {
      this.updateButtonText = "Hide Update Panel";
    }
    else {
      this.updateButtonText = "Show Update Panel";
    }
  }

  //Public Methods 
  onLOBChange(selectedvalue) {

    
    this.coverageList = this.lineOfBusinessList.filter(x => x.ID == selectedvalue.ID)[0].Coverages;

  }

  onCoverageChange(selectedvalue) {
    this.getPolicyFormMasterList(selectedvalue.ID);
  }

  onChangeFormType(selectedValue) {

  }

  //Private Methods
  /**
   * configure grid
   */
  private configureGrid(): void {
    this.initialRowDataLoad$ = [];
    this.formsGridOptions = <GridOptions>{
      rowSelection: "multiple",
      domLayout: 'normal',
      columnDefs: this.createColumDef(),
      enableFilter: true,
      enableSorting: true,
      pagination: true,
      enableColResize: true,
      paginationPageSize: 20,
      enableBrowserTooltips: true,
      frameworkComponents: {
        dateFilterComponent: DateFilterComponent,
        booleanFilterComponent: BooleanFilterComponent
      },
      onGridReady: () => {
        this.formsGridOptions.api.setRowData([]);
        this.formsGridOptions.api.sizeColumnsToFit();
        this.pinnedTopRowData = [];
        // this.gridOptions.api.softRefreshView();
      },
      onRowClicked: (event) => {
      },
      onFilterChanged: (event) => {
        //Api call to get the filter data
        //console.log(event);

      },
      onSortChanged: (event) => {

      },
      onFilterModified: function (event) {
        //console.log(event);
      }
    };

  }

  /**
   * Configure Columns
   */
  private createColumDef(): any[] {
    let result: any[] = [
      {
        headerName: "",
        field: "IsSelected",
        filter: "agTextColumnFilter",
        headerCheckboxSelection: true,
        headerCheckboxSelectionFilteredOnly: true,
        checkboxSelection: true,
        width: 75,
        //cellRenderer: params => {
        //  return `<input type='checkbox' data-action-type="Edit"  ${params.value ? 'checked' : ''} />`;
        //}
      },
      { headerName: 'Internal Form Number', field: 'InternalFormNumber', tooltipField: "FormType", sortable: true, filter: 'agTextColumnFilter', resizable: true, comparator: function (valueA, valueB, nodeA, nodeB, isInverted) { return valueA.toLowerCase().localeCompare(valueB.toLowerCase()); } },
      { headerName: 'Form Name', width: 300, field: 'FormName', filter: 'agTextColumnFilter', tooltipField: "FormType", sortable: true, resizable: true, comparator: function (valueA, valueB, nodeA, nodeB, isInverted) { return valueA.toLowerCase().localeCompare(valueB.toLowerCase()); } },
      {
        headerName: 'Effective Date', field: 'EffectiveDate', filter: 'dateFilterComponent', tooltipField: "FormType", width:130, sortable:true ,cellRenderer: params => {
          return this.dateTimePipe.transform(params.value);
        }
      },
      {
        headerName: 'Expiration Date', field: 'ExpirationDate', width: 130, resizable: true, tooltipField: "FormType", sortable: true,filter: 'dateFilterComponent', cellRenderer: params => {
          return this.dateTimePipe.transform(params.value);
        }
      },
      {
        headerName: 'Submission Expiration Date', field: 'SubmissionExpirationDate', width: 130, tooltipField: "FormType", resizable: true, sortable: true,filter: 'dateFilterComponent', cellRenderer: params => {
          return this.dateTimePipe.transform(params.value);
        }
      },
      {
        headerName: 'Form Type', field: 'FormType', resizable: true, sortable: true, filter: 'agTextColumnFilter', tooltipField: "FormType"
      },
      {
        headerName: 'Previous Version', resizable: true, filter: 'agTextColumnFilter', sortable: true, field: 'PriorInternalFormNumber', tooltipField: "FormType"
      },
      {
        headerName: 'Sort', resizable: true, sortable: true, width: 60, field: 'SortOrder', tooltipField: "FormType"
      },
      {
        headerName: 'Frequently Used', resizable: true, suppressSorting: true, width: 120, tooltipField: "FormType", field: 'FrequentlyUsed', filter: 'booleanFilterComponent', cellRenderer: params => {
          return `<input type='checkbox' ${params.value ? 'checked' : ''} disabled/>`;
        }
      },
      {
        headerName: 'Multiple LOBs', resizable: true, suppressSorting: true, width: 120, tooltipField: "FormType", field: 'MultiLOBIndicator', filter: 'booleanFilterComponent', cellRenderer: params => {
          return `<input type='checkbox' ${params.value ? 'checked' : ''} disabled/>`;
        }
      },
      {
        headerName: 'Has Mandatory Rule', resizable: true, suppressSorting: true, tooltipField: "FormType", width: 120, field: 'Mandatory', filter: 'booleanFilterComponent', cellRenderer: params => {
          return `<input type='checkbox' ${params.value ? 'checked' : ''} disabled/>`;
        }
      }
    ]


    return result;
  }

  onSelectionChanged(event) {
    var rowCount = event.api.getSelectedNodes().length;

    let tempSelectedFormsRules: RuleEngineForm[];
    this.selectedFormsRules = [];
    for (var i = 0; i < rowCount; i++) {
      this.selectedFormsRules.push(event.api.getSelectedNodes()[i].data);
    }

    this.pinnedTopRowData = this.selectedFormsRules;
    // this.formsGridOptions.pinnedTopRowData = this.selectedFormsRules;
  }

  runQuery() {
    var exportingForms: PolicyFormExport[] = [];
    const replacer = (key, value) => value === null ? '' : value;
    const header = ["InternalFormNumber", "FormName", "EffectiveDate", "ExpirationDate", "SubmissionExpirationDate", "FormType", "PreviousVersion", "SortOrder", "FrequentlyUsed", "Multiple LOBs", "Mandatory"];
    let csv: string[] = [];
    csv.push(header.join(','));
    for (var form of this.policyFormList) {
      console.log(form);
      let exForm = new PolicyFormExport();
      exForm.InternalFormNumber = form.InternalFormNumber;
      exForm.FormName = form.FormName.toString().replaceAll('"', '');
      exForm.FormName = exForm.FormName.replace('/,/g', '_');
      exForm.FormName = exForm.FormName.replace(/(\r\n|\n|\r)/gm, "");
      exForm.EffectiveDate = new Date(form.EffectiveDate * 1000).toISOString().slice(0, 10);
      if (form.ExpirationDate !== null) {
        exForm.ExpirationDate = new Date(form.ExpirationDate * 1000).toISOString().slice(0, 10);
      }
      if (form.SubmissionExpirationDate !== null) {
        exForm.SubmissionExpirationDate = new Date(form.SubmissionExpirationDate * 1000).toISOString().slice(0, 10);
      }
      exForm.FormType = form.FormType;
      exForm.PriorInternalFormNumber = form.PriorInternalFormNumber;
      exForm.SortOrder = form.SortOrder;
      exForm.FrequentlyUsed = form.FrequentlyUsed;
      exForm.MultipleLOBs = form.MultiLOBIndicator;
      exForm.Mandatory = form.Mandatory;
      csv.push(exForm.InternalFormNumber + ',' + exForm.FormName + ',' + exForm.EffectiveDate + ',' + exForm.ExpirationDate + ',' + exForm.SubmissionExpirationDate + ',' + exForm.FormType + ',' + exForm.PriorInternalFormNumber + ',' +
        exForm.SortOrder + ',' + exForm.FrequentlyUsed.toString() + ',' + exForm.MultipleLOBs.toString() + ',' + exForm.Mandatory.toString());
      
    }
    let csvArray = csv.join('\r\n');
    var blob = new Blob([csvArray], { type: 'text/csv' });
    saveAs(blob, "export.csv");
  }


  /**
   * Get Master list of Policy Forms based on lob name
   * @param lobName
   */
  private getPolicyFormMasterList(lobName: string) {
      /*Check if lob name contains '&' symbol then encode with %26.This will fix value truncating issue with querstring parameter */
    if (lobName.indexOf('&') > -1) {
      lobName = lobName.replace("&", "%26");
    }
    //CR in new data structure need to get the LOB attribute set for forms, using filter because find isn't supported by current compiler
    this.policyFormsService.getAdminFormsForLOB(lobName).subscribe((res: AdminForm[]) => {
      //console.log(res);
      this.adminFormList = res;
      this.policyFormList = [];
      //Take attribute set of current form and put all relevant info into Rule Engine Form data structure for display and deploying
      for (var item of this.adminFormList) {
         let ruleEngineForm = new RuleEngineForm();
         var lobSet = item.AttributeSets.find(element => element.Scope == lobName);
         if(lobSet != null){
           ruleEngineForm.AdminFormId = item.id;
           ruleEngineForm.InternalFormNumber = item.InternalFormNumber;
           ruleEngineForm.ExternalFormNumber = item.ExternalFormNumber;
           ruleEngineForm.MultiLOBIndicator = item.MultiLOBIndicator;
           ruleEngineForm.CreationDate = item.CreationDate;
           ruleEngineForm.CreatedByName = item.CreatedByName;
           ruleEngineForm.ModifiedDate = item.ModifiedDate;
           ruleEngineForm.ModifiedByName = item.ModifiedByName;
           ruleEngineForm.LOB = lobName;
           ruleEngineForm.PriorInternalFormNumber = item.PriorInternalFormNumber;
           if (item.RuleSetups.find(set => set.LOB.indexOf(lobName) != -1) != null) {
             ruleEngineForm.RuleSet = item.RuleSetups.find(set => set.LOB.indexOf(lobName) != -1).RuleSet;
           }
           
          
          //In future state all of these value should be present, for now check if undefined before setting

           ruleEngineForm.FormName = lobSet.FormName;
           ruleEngineForm.SortOrder = lobSet.SortOrder;
           ruleEngineForm.FormType = lobSet.FormType;
           ruleEngineForm.EffectiveDate = lobSet.EffectiveDate;
           ruleEngineForm.ExpirationDate = lobSet.ExpirationDate;
           ruleEngineForm.SubmissionExpirationDate = lobSet.SubmissionExpirationDate;
           ruleEngineForm.FrequentlyUsed = lobSet.FrequentlyUsed;
           var mandatory = false;
           if (item.RuleSetups.find(element => element.LOB.indexOf(lobName) != -1) != undefined) {
            for (var rule of item.RuleSetups.find(element => element.LOB.indexOf(lobName) != -1).RuleSet.Rules) {
             if (rule.Expressions.some(x => x.MandatoryList.length > 0)) {
               mandatory = true;
             }
           }
           }

           ruleEngineForm.Mandatory = mandatory;
           this.policyFormList.push(ruleEngineForm);
         }
      }
      this.formsGridOptions.api.setRowData(this.policyFormList);

    });
  }

  /**
   *Set data to grid
   * */
  private setRowData() {
    if (this.policyFormList != null) {
      this.policyFormList.sort((a: any, b: any) => {
        var nameA = a.InternalFormNumber.toLowerCase();
        var nameB = b.InternalFormNumber.toLowerCase();
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }

        // names must be equal
        return 0;
      });
      this.formsGridOptions.api.setRowData(this.policyFormList);
    }

  }

  //Update selected admin forms with info from fill in fields
  updateSelectedForms() {
    var isAllNull = true;
    var selectedAdminIDs = this.formsGridOptions.api.getSelectedRows().map(obj => obj.AdminFormId);
    var selectedAdminForms = this.adminFormList.filter(x => selectedAdminIDs.includes(x.id));
    console.log(selectedAdminForms);
    if (this.updateFormType != null && this.updateFormType != "") {
      isAllNull = false;
      for (var i = 0; i < selectedAdminForms.length; i++) {
        var index = selectedAdminForms[i].AttributeSets.findIndex(ind => ind.Scope == this.selectedLOB);
        selectedAdminForms[i].AttributeSets[index].FormType = this.updateFormType;
      }
    }
    if (this.updateEffectiveDate != null && this.updateEffectiveDate != 0) {
      isAllNull = false;
      for (var i = 0; i < selectedAdminForms.length; i++) {
        var index = selectedAdminForms[i].AttributeSets.findIndex(ind => ind.Scope == this.selectedLOB);
        selectedAdminForms[i].AttributeSets[index].EffectiveDate = this.updateEffectiveDate;
      }
    }
    if (this.updateExpirationDate != null && this.updateExpirationDate != 0) {
      isAllNull = false;
      for (var i = 0; i < selectedAdminForms.length; i++) {
        var index = selectedAdminForms[i].AttributeSets.findIndex(ind => ind.Scope == this.selectedLOB);
        selectedAdminForms[i].AttributeSets[index].ExpirationDate = this.updateExpirationDate;
      }
    }
    if (this.updateSubmissionExpirationDate != null && this.updateSubmissionExpirationDate != 0) {
      isAllNull = false;
      for (var i = 0; i < selectedAdminForms.length; i++) {
        var index = selectedAdminForms[i].AttributeSets.findIndex(ind => ind.Scope == this.selectedLOB);
        selectedAdminForms[i].AttributeSets[index].SubmissionExpirationDate = this.updateSubmissionExpirationDate;
      }
    }
    //
    if (isAllNull) {
      confirm("Error: No fields to update have been filled in");
    }
    else {
      if (confirm(`You are about to update ${selectedAdminForms.length} form attribuites for LOB ${this.selectedLOB} with the information from the filled in fields, continue?`)) {
        this.policyFormsService.updateForms(selectedAdminForms).subscribe(res => {
          if (res == true) {
            this.toastr.success("Successfully updated documents, refreshing...");
            setTimeout(() => location.reload(), 2000);
          }
          else {
            this.toastr.error("A problem occurred while updating documnts");
            setTimeout(() => location.reload(), 2000);
          }
        })
      }
    }
  }


}
