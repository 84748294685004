//Angular Imports 
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";

//Third Party Imports
import { Observable, Subscriber } from "rxjs";
import { map } from "rxjs/operators";

export interface PremiumAudit {
  CreatedOn?: Date;
  IssuedOn?: Date;
  TransactionId?: string;
  FormId?: number;
  PremiumName?: string;
  FormPremiumAmount?: string;
  Change_Premium_From_e2?: string;
  DocumentNumber?: string;
  Lob?: string;
  CreatedBy?: string;
  ActionType?: string;
  PolicyNumber?: string;
  DealNumber?: string;
  ReviewedBy?: string;
  ReviewedOn?: Date;
}

/**
  * Policy Forms Provider
*/
@Injectable()
export class MGDReportsService {

  private masterData: any;
  /**
   * Constructor
   * @ignore
  */
  constructor(private http: HttpClient) {
  }

  getEmailSettings(): Observable<string> {
    let url = 'api/Configs/GetReportUrl';
    return this.http.get<string>(url).pipe(map(res => {
      return res;
    }));
  }

  getAllPremiumAudit(env: string, token: string): Observable<PremiumAudit[]> {
    var gurl = `https://extension-${env}.azurewebsites.net/api/premiumaudit/getAll`;
    if (env.toLowerCase() == "local") {
      gurl = `https://localhost:61000/api/premiumaudit/getAll`;
    }
    var headers = new HttpHeaders();
    headers = headers.append('Authorization', `Bearer ${token}`);
    return this.http.get<any>(gurl, { headers }).pipe(map(res => res));
  }

  updatePremiumAudit(env: string, token: string, updatedPremiumAudits: PremiumAudit[]): Observable<PremiumAudit[]> {
    var gurl = `https://extension-${env}.azurewebsites.net/api/premiumaudit/update`;
    if (env.toLowerCase() == "local") {
      gurl = `https://localhost:61000/api/premiumaudit/update`;
    }
    var headers = new HttpHeaders();
    headers = headers.append('Authorization', `Bearer ${token}`);
    return this.http.post<any>(gurl, updatedPremiumAudits, { headers }).pipe(map(res => res));
  }


}
