//Angular Imports
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { APP_BASE_HREF, CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { HttpClient, HttpClientModule, HttpHeaders, HTTP_INTERCEPTORS } from '@angular/common/http';

//Active directory wrapper
//import { MsalModule, MsalInterceptor } from '@azure/msal-angular'

import { MsalModule } from './msal';

//Third Party Imports 
import { NgbModalModule, NgbModule } from '@ng-bootstrap/ng-bootstrap';

//Internal Imports
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app.routing';
import { EnvironmentSpecifics, FrameworkModule, SkippableInterceptorHeader } from './framework';

import { ToastrModule } from 'ngx-toastr';
import { LogLevel } from 'msal';
import { VexModule } from '../@vex/vex.module';
import { SplashModule } from './splash';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { AppInitService } from './init.service';
import { Observable, tap } from 'rxjs';

/**
  Providers
*/
const PROVIDERS = [
  { provide: APP_BASE_HREF, useValue: '/' },
  { provide: APP_INITIALIZER, useFactory: initializeApp1, deps: [AppInitService], multi: true }
  //{ provide: APP_INITIALIZER, useFactory: initalizeAppFactory, deps: [HttpClient], multi:true }
  //{ provide: HTTP_INTERCEPTORS, useClass: MsalInterceptor, multi: true }
]


export function loggerCallback(logLevel, message, piiEnabled) {
  console.log("Msal client logging: " + message);
}

// *******************  PB 2/19/20   ************************************
// The values below need to also be set in the msal.service.ts file
//
// Set the app registration ID for the current environment based on the url.
// PB - I did it this way because I could not figure out how to use the environment service in the app module.

var replyUrl = "";
var clientID = "";


export function initializeApp1(appInitService: AppInitService) {
  return (): Promise<any> => {
    return appInitService.Init().then(res => {
      let envDetails = <EnvironmentSpecifics>(res);
      console.log(envDetails);
      replyUrl = replyUrl;
      clientID = clientID;
      window.sessionStorage.setItem("clientID", envDetails.AZUREAD_CLIENTID);
      window.sessionStorage.setItem("replyUrl", envDetails.AZUREAD_REPLY_URL);
      window.sessionStorage.setItem("redirect_uri", envDetails.AZUREAD_REPLY_URL);
    });
    
  }
}

function initalizeAppFactory(httpClient: HttpClient): () => Observable<EnvironmentSpecifics> {
  let headers = new HttpHeaders().set(SkippableInterceptorHeader.RequestSkipHeader, 'true')
    .set(SkippableInterceptorHeader.AppInsightsSkipHeader, 'true')
    .set(SkippableInterceptorHeader.JWTSkipHeader, 'true')
    .set(SkippableInterceptorHeader.IfModifiedSkipHeader, 'true');
  return () => httpClient.get<EnvironmentSpecifics>("appsettings.json", { headers: headers }).pipe(
    tap(res => { console.log(res); clientID = res.AZUREAD_CLIENTID; replyUrl = res.AZUREAD_REPLY_URL; window.localStorage.setItem("clientID", clientID); window.localStorage.setItem("replyUrl", replyUrl); }));
}

/**
  App Module
*/
@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    CommonModule,
    HttpClientModule,
    FrameworkModule,
    NgbModule,
    RouterModule,
    AppRoutingModule,
    NgbModalModule,
    VexModule,
    SplashModule,
    ToastrModule.forRoot({
      positionClass: 'toast-bottom-right'
    }),
    MsalModule.forRoot({
      // LOGINFIX - Added _clientID property to MsalConfig so I could add setter/getter
      // and track when the value was being updated
      _clientID: clientID,
      clientID: clientID,
      authority: "https://login.microsoftonline.com/28e5a145-70c6-44e3-ba17-7b09d54fe531",
      validateAuthority: true,
      redirectUri: replyUrl,
      cacheLocation: "sessionStorage",
      //postLogoutRedirectUri: "",
      navigateToLoginRequestUrl: true,
      popUp: false,
      consentScopes: ["user.read"],
      unprotectedResources: [],
      //protectedResourceMap: protectedResourceMap,
      logger: loggerCallback,
      correlationId: '1234',
      level: LogLevel.Info,
      piiLoggingEnabled: true
    })
  ],
  providers: [PROVIDERS],
  bootstrap: [AppComponent]
})

export class AppModule { }
