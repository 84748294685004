//Angular Imports 
import { Component, HostBinding, Input } from '@angular/core';

//Third Party Imports 
import { GridOptions } from 'ag-grid-community';
import * as _ from 'lodash';
import { ToastrService } from 'ngx-toastr';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

//Internal Imports 
import {
  ApplicationInsightsBaseComponent, AppInsightsService, DateFilterComponent, BooleanFilterComponent,
  DateTimePipe,  SharedService, EnvironmentService
} from '../../../framework';
import { AttributeSet, AdminForm } from '../../models';
import { FormPageService } from '../../services';

//import { FormPageConstants } from '../../configs/form-page-constants';

import 'rxjs';
import { saveAs } from 'file-saver';
import { ProductCoverage, ProductDefinition } from '../../../products/models/productDefinition';
import { forEach } from 'lodash';
import { isNullOrUndefined } from 'util';

//import { RuleEngineResult, SingleFormResult, RuleEngineLog, TestExecution, TestGroup, TestRun } from '../../models';



/**
 * Forms Component
*/
@Component({
  selector: 'app-form-page-attributes-container',
  templateUrl: './form-page-attributes-container.component.html',
  styleUrls: ['./form-page-attributes-container.component.scss']
})

export class FormPageAttributesContainerComponent extends ApplicationInsightsBaseComponent {

  @HostBinding('style.width') width: string;

  // Specifies the action type mode the component is in (Add, Edit, View)
  @Input() public actionType: string;

  @Input() currentForm: AdminForm;

  // The AttributeSet used to populate the fields
  @Input() public attributeSet: AttributeSet;

  // Specifies whether this is for Master or LOB (Master, LOB)
  public attributeSetIsMaster: boolean;

  @Input() public formTypeList: string[];
  public selectedFormType: string;

  // Contains validation errors for the SubmissionExpirationDate.  This date is required if
  // ExpirationDate is set, and it must be greater than ExpirationDate.
  submissionExpirationDateError: string;
  expirationDateError: string;

  public sortOrderChanges: boolean;

  //Hold product config list
  public MPLProduct: ProductDefinition = new ProductDefinition();

  //MPL stuff (flag to display "DisplayOnly" input, and supporting elements)
  public isMPL: boolean = false;
  public ActionReasonList: string[] = [];
  public displayString: string = "";


  /** 
   * Constructor
   * @ignore
  */
  constructor(private _appInsightsService: AppInsightsService, private toastr: ToastrService,
    private sharedService: SharedService, private formPageService: FormPageService) {
    super(_appInsightsService);
    this.width = "100%";
    this.submissionExpirationDateError = "";
    this.expirationDateError = "";
  }

  ngOnInit(): void {

    if (!this.attributeSet) {
      this.attributeSet = new AttributeSet();
    }
    this.sortOrderChanges = false;
    this.formPageService.getProducts().subscribe(res => {
      this.MPLProduct = res.find(a => a.ID == "EVE");
    });
    this.sharedService.getAllCpmElements().subscribe(res => {
      res = res.filter(x => x.CPMDataElementName == "Action Reason");
      this.ActionReasonList = res[0].CPMFieldValues.map(a => a.Code);
    })
    
  }

  ngOnChanges() {
    this.sortOrderChanges = false;
    if (this.attributeSet) {
      this.isMPL = this.isMPLLOB(this.attributeSet.Scope);
      this.selectedFormType = this.attributeSet.FormType;

      //console.log("AttributeSet Scope:" + this.attributeSet.Scope);
      if (this.attributeSet.Scope === this.formPageService.MasterAttributeScope) {
        this.attributeSetIsMaster = true;
      } else {
        this.attributeSetIsMaster = false;
        if (this.isMPL && !(isNullOrUndefined(this.attributeSet.DisplayOnly) || this.attributeSet.DisplayOnly.length == 0)) {
          this.displayString = this.attributeSet.DisplayOnly.join();
        }
        else {
          this.displayString = "";
        }
        
      }
    }
    
  }

  ngOnDestroy(): void {

  }

  isMPLLOB(scope: string): boolean {
    for (var i = 0; i < this.MPLProduct.Coverages?.length; i++){
      if (scope == this.MPLProduct?.Coverages[i].ID) {
        return true;
      }
    }
    return false;
  }
  
  // Update the AttributeSet when a form type is selected in the dropdown.
  onChangeFormType(event): void {
    this.attributeSet.FormType = event.Description;
  }

  onSelectActionReason(event): void {

  }

  onChangeExpirationDate(): void {
    // Set the SubmissionExpirationDate to ExpirationDate + 90 days
    if (this.attributeSet.ExpirationDate) {
      this.attributeSet.SubmissionExpirationDate = this.attributeSet.ExpirationDate + (86400 * 90);
    } else {
      this.attributeSet.SubmissionExpirationDate = null;
    }
    this.onChangeSubmissionExpirationDate();
    this.expirationDateError = "";

  }

  onChangeSubmissionExpirationDate(): void {
    this.submissionExpirationDateError = "";

  }

  onChangeEffectiveDate(): void {
    this.expirationDateError = "";

  }

  onSortOrderChange(): void {
      this.sortOrderChanges = true;
  }

  onExternalFormNumberChange(): void {
      this.attributeSet.InternalFormNumber = this.attributeSet.ExternalFormNumber;
  }

  validateAttributes(isNewForm: boolean): boolean {
    let isValid: boolean = true;
    let validationMessage: string = "Page contains validation errors.  Please correct and re-save.";

    // Determine if form number already exists.
    if (isNewForm) {
      if (this.formPageService.doesFormNumberExist(this.attributeSet.InternalFormNumber)) {
        this.toastr.error("Error saving form.  Form Number already exists.");
        return false;
      }
    }    

    // Validate FormName
    if (this.attributeSet.Scope === this.formPageService.MasterAttributeScope) {
      if (!this.attributeSet.FormName) {
        isValid = false;
      }
    }

    // ValidateFormType
    if (this.attributeSet.Scope === this.formPageService.MasterAttributeScope) {
      if (!this.attributeSet.FormType) {
        isValid = false;
      }
    }

    // Validate ExpirationDate
    if (this.attributeSet.EffectiveDate && this.attributeSet.ExpirationDate) {
      if (this.attributeSet.ExpirationDate <= this.attributeSet.EffectiveDate) {
        isValid = false;
        this.expirationDateError += "ExpirationDate must be greater than EffectiveDate."
      }
    }   

    // Validate SubmissionExpirationDate
    if (this.attributeSet.ExpirationDate) {
      // ExpirationDate is set so SubmissionExpirationDate must be set and must be after ExpirationDate
      if (this.attributeSet.SubmissionExpirationDate && this.attributeSet.SubmissionExpirationDate <= this.attributeSet.ExpirationDate) {
        isValid = false;
        this.submissionExpirationDateError += "SubmissionExpirationDate must be after ExpirationDate.";
      }      
    } else {
      // No ExpirationDate which means we should not have a SubmissionExpirationDate
      if (this.attributeSet.SubmissionExpirationDate) {
        isValid = false;
        this.submissionExpirationDateError += "ExpirationDate is not set so SubmissionExpirationDate should be empty."
      }
    }

    if (!isValid) {
      this.toastr.error(validationMessage);
    }

    return isValid;
  }

}
