<div class="row row-full-width">
  <div class="col-7 pr-1">
    <div class="card">
      <!-- Heading -->
      <div class="row">
        <div style="float: left">
          <h3 class="card-title card-title-custom" >
            Master Form Attributes
          </h3>
        </div>

        <div
          class="btn_wrapper"
          style="
            margin-left: auto;
            margin-right: 0;
            margin-top: 10px;
            float: right;


          "
        >
          <button
            type="button"

            *ngIf="masterActionType === 'View' && FormsWritePermission"
            style="background-color: var(--color-teal)"
            mat-raised-button
            class="white-text btn-teal"
            (click)="onEditMaster()"
          >
            Edit
          </button>
          <button
            type="button"
            *ngIf="masterActionType === 'Edit' && FormsWritePermission"
            mat-raised-button
            class="white-text btn-teal mrgrgt10"
            (click)="onSaveMaster()"
          >
            Save
          </button>
          <button
            type="button"
            *ngIf="masterActionType === 'Edit'"
            style="background-color: var(--color-teal)"
            mat-raised-button
            class="white-text btn-teal"
            (click)="onDiscardMaster()"
          >
            Discard
          </button>
        </div>
      </div>

      <!-- Content -->
     <div class="container-fluid" >
             <div class="row" style="margin: 0">
          <div class="col-md-5" style="padding: 0">
            <div
              id="lob-grid-container"
              class="col-md-12"
              style="padding-bottom: 10px"
              *ngIf="masterActionType === 'Edit'"
            >
              

            
            <h3 class="label_lineofbusiness">Lines of Business</h3>
                <input
                  type="text"
                  matInput
                  class="form-page-att-input"
                  style="width: 100%"
                  
                 
                  [matAutocomplete]="autoComplete"
                  placeholder="Select items"
                  [formControl]="itemControl"
                />
                <mat-autocomplete #autoComplete="matAutocomplete">
                  <mat-option *ngFor="let item of filteredItems" [value]="item.VisibleDescription" (click)="optionClicked($event, item)">
                    <mat-checkbox
                      [checked]="item.Selected"
                      (change)="toggleSelection(item)"
                      (click)="$event.stopPropagation()"
                    >
                      {{ item.VisibleDescription }}
                    </mat-checkbox>
                  </mat-option>
                </mat-autocomplete>
           
                <div class="chip-list-container" *ngIf="selectedItems.length > 0">
              <mat-chip-list #chipList>
                <mat-chip *ngFor="let item of selectedItems" [selectable]="true" [removable]="true" (removed)="remove(item)">
                  {{ item.VisibleDescription }}
                  <mat-icon matChipRemove>cancel</mat-icon>
                </mat-chip>
              </mat-chip-list>
              </div>
              <div
                style="
                 
                  vertical-align: middle;
                  align-content: center;
                  align-items: center;
        
                  display: grid;
                "
              >
                <span class="error_msg pr-0" style="width:272px">
                  <i class="error_icon"></i>Before removing a LOB, please expire
                  this form/LOB, see Confluence page
                  <a
                    target="_blank"
                    href="https://confluence.markelcorp.com/display/ITFO/Removing+LOBs+From+Forms"
                    >here for context</a
                  >
                </span>
               
              </div>
             
            </div>

            

           <!-- Unordered List for View Mode -->
           
      <div id="lob-list-container" class="col-12 " style="padding-bottom: 10px; width: 90%;" *ngIf="masterActionType !== 'Edit'">
       
        <h3 class="label_lineofbusiness">Lines of Business</h3>
    
        
  <ul class="list-group lob-list no-border">
    <li class="list-group-item1" 
        *ngFor="let lob of lobList" 
        [ngClass]="{'selected': lob === selectedLob}"  
        (click)="onLobClick(lob)">
      {{ lob }}
    </li>
  </ul>
       
      </div>
    </div>

          <div class="col-md-7" style="padding: 0">
            <app-form-page-attributes-container
              [attributeSet]="masterAttributeSet"
              [currentForm]="currentForm"
              [formTypeList]="formTypeList"
              [actionType]="masterActionType"
              #masterAttributeSetComponent
            >
            </app-form-page-attributes-container>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- LOB Specific section-->
  <div class="col-5">
    <div class="card" >
      <!-- Heading -->
      <div class="row">
        <h4
          class="card-title"
         
        >
          LOB Attributes
        </h4>

        <div
          class="btn_wrapper"
          style="
            margin-left: auto;
            margin-right: 0;
            float: right;
            margin-top: 10px;
          "
        >
          <button
            type="button"
            *ngIf="
              lobActionType === 'View' && selectedLob && FormsWritePermission
            "
            mat-raised-button
            class="btn-teal"
            (click)="onEditLOB()"
          >
            Edit
          </button>
          <button
            type="button"
            *ngIf="lobActionType === 'Edit' && FormsWritePermission"
            mat-raised-button
            class="btn-teal mrgrgt10"
            (click)="onSaveLOB()"
          >
            Save
          </button>
          <button
            type="button"
            *ngIf="lobActionType === 'Edit' && FormsWritePermission"
            mat-raised-button
            class="btn-teal"
            (click)="onDiscardLOB()"
          >
            Discard
          </button>
        </div>
      </div>

      <!-- LOB List -->
      <!-- <div class="row" style="margin-top: 20px;
     ">
        <div class="mrgbtm10 fast_admin" style="width: 100%; margin-left: 40px;">
          <label class="form-att-label " style="float: left; margin-top: 10px;">Lines of Business</label>
          <div class="selectdiv select_wrapinline">
            <label>
              <ng-select
                id="activeLobList"
                class="custom-select"
                style="width: 100%"
                [items]="lobList"
                bindLabel=""
                bindValue=""
                [(ngModel)]="selectedLob"
                (change)="onSelectLob()"
              >
              </ng-select>
            </label>
          </div>
        </div>
      </div> -->

      <!-- Content -->

      <div class="row">
        <div class="lob-content-container">

          <app-form-page-attributes-container
            [attributeSet]="currentLobAttributeSet"
            [currentForm]="currentForm"
            [formTypeList]="formTypeList"
            [actionType]="lobActionType"
            #lobAttributeSetComponent
          >
          </app-form-page-attributes-container>
        </div>
      </div>
    </div>
  </div>
</div>
