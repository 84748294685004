<div class="component-container">
  <div class="component-heading-container">
    <h3 class="component-heading-text">
      Print Order Setup - Changes made on this page require running the Copy
      Reference Collections script.
    </h3>
  </div>
  <div class="component-content-view">
    <div class="container-fluid">
      <div id="policy-form-container" class="row">
        <div class="col-md-12 p-0">
          <div
            class="heading-container fast_admin"
            id="policy-form-heading-container"
          >
            <label class="label_heading">Line Of Business:</label>
            <div class="selectdiv select_wrapinline">
              <label>
                <ng-select
                  id="lineOfBusiness"
                  class="custom-select"
                  [items]="lineOfBusinessList"
                  bindLabel="Description"
                  bindValue="Name"
                  (change)="onLOBChange($event)"
                  [(ngModel)]="selectedLOB"
                >
                  <ng-template ng-option-tmp let-item="item">
                    <div title="{{ item.Description }}">
                      {{ item.Description }}
                    </div>
                  </ng-template>
                </ng-select>
              </label>
            </div>
            <span
              *ngIf="PrintOrderWritePermission"
              class="policy-form-btn"
              id="policy-form-add-btn"
              (click)="openPrintOrder('Add')"
            >
              <i class="fas fa-plus"></i>
            </span>
          </div>
        </div>
        <div id="policy-form-content-container" class="col-md-12 mrgbtm10">
          
            <ag-grid-angular class="ag-theme-balham fast_panel_table"
            style="
            height: calc(100vh - 200px) !important;
            overflow-x: hidden !important;
            border-radius: 7px;
            box-shadow: 0 0 7px #fff;
          
          "
            [gridOptions]="printOrderGridOptions"
          >
          </ag-grid-angular>
        </div>
      </div>
    </div>
  </div>
</div>
