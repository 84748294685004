//Angular Imports 
import { Routes, RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { MsalGuard } from '../msal/msal-guard.service';

//Internal Imports
import { AuthenticationResolver } from '../framework';
import { AuthGuard } from '../framework/guards/auth.guard';
import { SplashComponent } from './components/splash.component';

export const routes: Routes = [
  {
    path: '', component: SplashComponent,
    resolve: {
      token: AuthenticationResolver
    },
    children: [
      {
        path: 'main',
        loadChildren: () => import('../../../src/app/splash/splash-items/splash-items.module').then(m => m.SplashItemsModule)
      }
    ]
  }
];

/**
  Main Layout Routing Module
*/
@NgModule({
    imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: [AuthenticationResolver]
})

export class SplashRoutingModule {
}
