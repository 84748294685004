//Angular Imports
import { Component, HostBinding, OnInit, ViewChild } from "@angular/core";

//Third Party Imports
import { GridOptions } from "ag-grid-community";
import * as _ from "lodash";
import { ToastrService } from "ngx-toastr";

//Internal Imports

import "rxjs";
import { saveAs } from "file-saver";
import { filter, forkJoin, map, startWith } from "rxjs";
import { MatButtonToggleModule } from "@angular/material/button-toggle";
import {
  AppInsightsService,
  ReportService,
  EnvironmentService,
  DateTimeUtcPipe,
  SharedService,
  ApplicationInsightsBaseComponent,
  CPMElement,
  UserProfileService,
  UserProfile,
  DateFilterComponent,
  BooleanFilterComponent,
} from "../../../framework";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { MigrationService } from "../services/migration.service";
import { MigrationLogs, MigrationSummaryLogs } from "../../models/migrationLogs";
import { TestCaseError } from "../../testSuites/testHarness/components/testCaseError/testCaseError.component";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";

/**
 * Forms Component
 */
@Component({
  selector: "app-document-migration",
  templateUrl: "./migration.component.html",
  styleUrls: ["./migration.component.scss"],
  providers: [],
})
export class MigrationComponent implements OnInit {



  public userProfile: UserProfile;

  public migrationLogs: MigrationLogs = new MigrationLogs();

  public displayList: boolean = false;

  public migrationErrorGridOptions: GridOptions;


  constructor(
    public userProfileService: UserProfileService,
    public sharedService: SharedService,
    public migrationService: MigrationService,
    private router: Router,
    private route: ActivatedRoute,
    private _modal: NgbModal
  ) {
    this.userProfile = userProfileService.UserProfile;
  }

  ngOnInit(): void {
    /*
     Hide the preloader div on the index.html pages on init. If you don't do this, the UI of the home component
     will be hidden on page reload / hot reload
    */
    this.sharedService.hidePreloader();
    this.configureGrid();
    this.migrationService.getMigrationLogs().subscribe(res => {
      this.migrationLogs = new MigrationLogs();
      for (var i = 0; i < res.summaryLogs.length; i++) {
        var tempSummaryLog = new MigrationSummaryLogs();
        tempSummaryLog.FilesUploaded = res.summaryLogs[i]['Files Successfully Uploaded'];
        tempSummaryLog.FilesFailed = res.summaryLogs[i]['Files Unsuccessfully Uploaded'];
        tempSummaryLog.TimeToComplete = res.summaryLogs[i]['Time To Complete'];
        tempSummaryLog.TimeToComplete = parseFloat(tempSummaryLog.TimeToComplete.toFixed(2));
        console.log(tempSummaryLog);
        this.migrationLogs.summaryLogs.push(tempSummaryLog);
      }
      this.migrationLogs.errorLogs = res.errorLogs;
      console.log(this.migrationLogs.errorLogs);
      this.displayList = true;

      this.migrationErrorGridOptions.api.setRowData(this.migrationLogs.errorLogs);
    })

    

  }

  private configureGrid(): void {
    this.migrationErrorGridOptions = <GridOptions>{
      rowSelection: "single",
      domLayout: "autoHeight",
      columnDefs: this.createColDef(),
      enableFilter: true,
      enableSorting: true,
      pagination: true,
      paginationPageSize: 8,
      rowHeight: 50,
      enableColResize: true,
      context: { componentParent: this },
      frameworkComponents: {
        dateFilterComponent: DateFilterComponent,
        booleanFilterComponent: BooleanFilterComponent
      },
      onGridReady: () => {
        this.migrationErrorGridOptions.api.setRowData([]);
        this.migrationErrorGridOptions.api.sizeColumnsToFit();
      },
      onRowClicked: (event) => {
        this.onRowClick(event);
      },
      onFilterChanged: (event) => { },
      onSortChanged: (event) => { },
      onFilterModified: function (event) {
        //console.log(event);
      },
    };
  }

  private createColDef(): any[] {
    let columns = [
      {
        headerName: "Item Number",
        field: "itemnum",
        filter: "agTextColumnFilter",
        width:250
      },
      {
        headerName: "Document",
        field: "filename",
        filter: "agTextColumnFilter",
        width:250
      },
      { headerName: "Item Name", field: "itemname", filter: "agTextColumnFilter", flex: 1 },
      {
        headerName: "Actions",
        width: 70,
        flex: 1,
        suppressMenu: true,
        suppressSorting: true,
        filter: 'booleanFilterComponent',
        cellRenderer: params => {
          return `<img src="/assets/images/preview_icon.png" data-action-type="Preview" class="cursor_pointer" title="Preview">`;
          
        }
      }
    ];

    return columns;
  }

  private onRowClick(e: any): void {
    if (e.event.target !== undefined) {
      let data = e.data;
      let actionType = e.event.target.getAttribute("data-action-type");
      switch (actionType) {
        case "Preview":
          const modalRef = this._modal.open(TestCaseError, { windowClass: 'xl' });
          modalRef.componentInstance.errorText = data.errorMessage;
          break;
      }
    }
  }

}
