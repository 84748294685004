<div class="component-container">
  <div class="component-heading-container">
    <h3 class="component-heading-text">Deploy Packages</h3>
  </div>
  <div style="margin: 10px">
    <button
      type="button"
      mat-raised-button
      class="btn-teal"
      (click)="packageReport()"
    >
      Create Package Report
    </button>
    <h3 class="component-heading-text">
      Packages highlighted in yellow have been deployed for testing but have not been deployed to PROD in over 15 days.
    </h3>
  </div>
  <div class="component-content-view">
    <div class="container-fluid">
      <div id="deploy-container" class="row">
        <div
          id="deploy-content-container"
          class="col-12"
          style="overflow: visible; min-height: 100px"
        >
          <ag-grid-angular
            class="ag-theme-balham fast_panel_table"
            style="
            height: calc(100vh - 440px) !important;
            overflow-x: hidden !important;
            border-radius: 7px;
            box-shadow: 0 0 7px #fff;
          
          "
            [gridOptions]="deployGridOptions"
          >
          </ag-grid-angular>
        </div>
      </div>
    </div>
    <div id="forms-container" class="row">
      <label class="label_heading"
        >Deployment History (Selected Package) :</label
      >
      <div
        id="forms-content-container"
        class="col-md-12"
        style="overflow: visible; min-height: 100px"
      >
        <ag-grid-angular
          class="ag-theme-balham fast_panel_table"
          style="
          height: calc(100vh - 497px) !important;
          overflow-x: hidden !important;
          border-radius: 7px;
          box-shadow: 0 0 7px #fff;
        
        "
          [gridOptions]="historyGridOptions"
        >
        </ag-grid-angular>
      </div>
    </div>
    <div id="forms-container" class="row">
      <label class="label_heading">Forms (Selected Package) :</label>
      <div
        id="forms-content-container"
        class="col-md-12"
        style="overflow: visible; min-height: 100px"
      >
        <ag-grid-angular
          class="ag-theme-balham fast_panel_table"
          style="
          height: calc(100vh - 497px) !important;
          overflow-x: hidden !important;
          border-radius: 7px;
          box-shadow: 0 0 7px #fff;
        
        "
          
          

          [gridOptions]="formsGridOptions"
        >
        </ag-grid-angular>
      </div>
    </div>
  </div>
</div>
